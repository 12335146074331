import { DeletableResource, handleLatest, toLoadable } from '@/dataServices'
import { K } from '@/generic'
import { HTTPSource } from '@cycle/http'


export const cache = (root: string) => (HTTP: HTTPSource): DeletableResource => {
  const deleteCategory = 'clear-cache'

  return {
    deleteRequest: {
      category: deleteCategory,
      method: 'DELETE',
      url: `${root}/debug/clearcache`
    },
    deleteResponse: toLoadable(handleLatest(HTTP)(deleteCategory)({
      success: K(undefined),
      error: K(undefined)
    }))
  }
}
