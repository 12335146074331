import { CREATE_TABLE, SET_DATA } from '@/drivers/tanstackTableDriver'
import { Unloaded, Manager, isLoaded, C, loaded, value, mapLoaded, isLoading, isLoadError } from '@/generic'
import { dynamicModel, pageSinkTemplate, INIT, IGNORE, DERIVED } from '@/infrastructure'
import { ProjectConfiguration } from '@/prim'
import { addMonths } from 'date-fns'
import { intent } from './intent'
import { timesheetTableColumns } from './timesheetTable'
import { DayReportRecord, emptyTimesheetViewModel, getUserTimesheetResource, Report, selectedMemberStorageKey, TimesheetSources, TimesheetViewModel } from './types'
import { asSimple, caseValue } from '@/fsharp'
import { cache } from '@/prim/resources/cache'

const { set, props } = Manager<TimesheetViewModel>()

export function setCanSyncOrRefresh(viewModel: TimesheetViewModel): TimesheetViewModel {
  const reportLoadedAndUsernameSelected = (isLoaded(viewModel.table) || (isLoadError(viewModel.table))) && !!viewModel.selectedProjectMember
  return set(state => state.canRefresh)(reportLoadedAndUsernameSelected && !viewModel.isSyncing)
    .and(state => state.canSync)(reportLoadedAndUsernameSelected && !viewModel.isSyncing)(viewModel)
}

const validProjectMemberExists = (config: ProjectConfiguration, tiaUserId: string) => config.info.members.some(m => m.tiaUserId === tiaUserId && m.timeTrackerId)

export const model = (intents: ReturnType<typeof intent>, sources: TimesheetSources) =>
  dynamicModel(sources.state.stream)(
    pageSinkTemplate,
    intents,
    {
      [INIT]: {
        state: emptyTimesheetViewModel,
        output: state => ({
          tanstackTable: [
            { type: CREATE_TABLE, key: state.timesheetReportTableKey, options: { columns: timesheetTableColumns, groupingState: ['date'] }},
            // { type: SET_GROUPING, key: state.timesheetReportTableKey, groupingState: ['date']}
          ]
        })
      },
      updateTable: C(loaded, set(state => state.table)),
      '.next-month': _ => set(props.selectedDate)(date => addMonths(date, 1)),
      '.previous-month': _ => set(props.selectedDate)(date => addMonths(date, -1)),
      '.project-member': {
        state: set(state => state.selectedProjectMember),
        output: tiaUserId => _ => ({
          storage: [{ key: selectedMemberStorageKey(sources.projectKey), value: tiaUserId }],
        })
      },
      loadProjectMember: set(state => state.selectedProjectMember),
      date: date => set(props.selectedDate)(date),
      loadProjectConfiguration: loadableConfiguration => state => {
        const setConfiguration = set(s => s.configuration)(loadableConfiguration)
        if (!isLoaded(loadableConfiguration) || !state.selectedProjectMember) {
          return setConfiguration(state)
        }

        const configuration = value(loadableConfiguration)

        if (validProjectMemberExists(configuration, state.selectedProjectMember)) {
          return setConfiguration(state)
        } else {
          return setConfiguration.and(s => s.selectedProjectMember)('')(state)
        }
      },
      // toggleDay: day => set(state => state.openDays)(toggleDay(day)),
      '.sync': {
        output: _ => state => ({
          HTTP: [getUserTimesheetResource(sources)(state).sync]
        })
      },
      '.refresh': {
        output: _ => state => ({
          HTTP: [getUserTimesheetResource(sources)(state).refresh]
        })
      },
      '.nuke-cache': {
        output: _ => _ => ({
          HTTP: [cache(sources.apiHost + '/api')(sources.HTTP).deleteRequest]
        })
      },
      // loadReport: timesheetResponse =>
      //   set(state => state.report)(mapLoaded(timesheetResponse, Report))
      //     .and(state => state.openDays)(prev => isLoaded(timesheetResponse) ? [] : prev)
      //     .and(state => state.syncResponse)(Unloaded),
      loadReport: {
        state: timesheetResponse =>
          set(state => state.report)(mapLoaded(timesheetResponse, Report))
            .and(state => state.syncResponse)(Unloaded),
        output: timesheetResponse => state => ({
          tanstackTable: [{
            type: SET_DATA,
            key: state.timesheetReportTableKey,
            data:
              isLoaded(timesheetResponse)
                ? value(timesheetResponse)
                  .timeEntries
                  .map((e): DayReportRecord => ({
                    description: e.description,
                    hours: e.hours,
                    taskName: e.taskName + (e.taskGroup ? ` (${caseValue(e.taskGroup)})` : ''),
                    tiaCodeName: e.tiaCodeName,
                    workDate: e.workDate,
                    workItem: asSimple(e.workItem)
                  }))
                : []
          }]
        })
      },
      syncResponse: response =>
        set(state => state.isSyncing)(isLoading(response))
          .and(state => state.syncResponse)(response),
      [DERIVED]: IGNORE
    })
