import xs from 'xstream'
import dropRepeats from 'xstream/extra/dropRepeats'
import { hasRole } from '../../authentication'
import { Anonymous, isUser } from '../../authentication/userProvider'
import { AuthActionType } from '../../drivers/msalDriver'
import { mergePageSinks, pageSinkMergeTemplate, PageSinks, PageSources } from '../../infrastructure'

export function withAuthenticated<T extends PageSources>(component: (sources: T) => any): (sources: T) => PageSinks
export function withAuthenticated<T extends PageSources>(role: string, component: (sources: T) => any): (sources: T) => PageSinks
export function withAuthenticated<T extends PageSources>(roleOrComponent: string | ((sources: T) => any), maybeComponent?: (sources: T) => PageSinks) {
  return (sources: T): any => {
    const [component, role] = typeof roleOrComponent === 'string' ? [maybeComponent, roleOrComponent] : [roleOrComponent, null]
    if (!component) {
      throw new Error('Missing component')
    }
    const authenticatedUser = sources.user.filter(isUser)

    // following to streams are only intended to block until the user has been loaded
    // not remembering them leads to spurious re-triggers of the application, breaking things
    const authorizedUser = authenticatedUser.filter(user => !role || hasRole(role)(user)).mapTo(undefined).take(1).remember()
    const unauthorizedUser = authenticatedUser.filter(user => !!role && !hasRole(role)(user)).mapTo(undefined).take(1).remember()

    const page$ = authorizedUser.map(() => component(sources)).remember()

    return mergePageSinks(page$, {
      ...pageSinkMergeTemplate,
      router: pageRouterSink =>
        xs.merge(
          unauthorizedUser.mapTo('/'),
          pageRouterSink),
      msal: pageAuthSink => xs.merge(
        pageAuthSink,
        sources.user.filter(x => x === Anonymous).compose(dropRepeats()).mapTo(AuthActionType.Login))
    })
  }
}
